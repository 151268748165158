import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {isMobile} from 'react-device-detect';
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,

  LineIcon,
  LinkedinIcon,

  TelegramIcon,

} from "react-share";

import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share";

const useStyleDrawers = makeStyles({
  paper: {
    background: 'unset',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    display: 'fixed',
    overflow: 'hidden',
  }
});

const ShareSocial = (props) => {
  const { setToggle, toggle } = props;
  const classesDrawer = useStyleDrawers();
  let title, link;
  if (typeof window !== "undefined") {
    title = window.document.title;
    link = window.location.href;
  }
 
  const content = `Tôi muốn chia sẻ với bạn về "${title}".`

  const shareMessage = () => {
    if (typeof window !== "undefined") {
      const noAppUrl = `http://www.facebook.com/dialog/send?display=page&app_id=585387055884129&link=${link}&redirect_uri=${link}`;
      const appUrl = `fb-messenger://share?app_id=585387055884129&link=${link}`;
      if (isMobile) {
  
        window.location = appUrl;
      } else {
        window.open(noAppUrl, '_blank', 'noopener,noreferrer')
      }
    }
  }
  return (
    <Drawer anchor={'bottom'} open={toggle} classes={{ paper: classesDrawer.paper }} >
        <div style={{ zIndex: -1, opacity: 0.15, background: 'black', width: '100%', height: '100%', position: 'absolute'}} onClick={setToggle}>
        </div>
        <div style={{ height: 370, padding: '20px 14px 0px 0px', background: 'white'}}>
          <h5 style={{ padding: '10px 20px'}}>Chia sẻ qua</h5>
          <List>
            <EmailShareButton subject={title} body={content} url={link}>
              <ListItem autoFocus>
                <ListItemAvatar>
                  <Avatar>
                    <EmailIcon/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Email" />
              </ListItem>
            </EmailShareButton>

          <FacebookShareButton title={content} url={link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                    <FacebookIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Facebook" />
            </ListItem>
          </FacebookShareButton>
          


          <div className="custom-social-message" onClick={shareMessage}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                    <FacebookMessengerIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Facebook Messenger" />
            </ListItem>
          </div>
          


          <LineShareButton title={content} url={link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                    <LineIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Line" />
            </ListItem>
          </LineShareButton>
          


          <LinkedinShareButton title={title} summary={content} url={link} source={link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                    <LinkedinIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Linked In" />
            </ListItem>
          </LinkedinShareButton>
          


          <TelegramShareButton title={content} url={link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                    <TelegramIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Telegram" />
            </ListItem>
          </TelegramShareButton>
        </List>
        </div>
    </Drawer>

  );
}

export default ShareSocial;